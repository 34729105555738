import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();

  // 初始化选项卡数据
  let tabs = [
    {
      value: 0,
      text: t('未读'),
    },
    {
      value: 1,
      text: t('已读'),
    },
  ];
  // 使用useState管理当前选择的类型，默认为银行卡
  const [type, setType] = useState(0);

  // 更改当前选择的类型
  const changeType = (type: number) => {
    setType(type);
  };
  return {
    tabs,
    type,
    changeType,
  };
};
