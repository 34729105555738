import { PhCaretRight } from '@/assets/icons/comm/PhCaretRight';
import PageEnum from '@/enums/pageEnum';
import SocketEnum from '@/enums/socketEnum';
import { sendReadMessageApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { history } from '@@/core/history';
import { useModel } from '@@/exports';
import { Divider } from '@nextui-org/react';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface OrderMessageCardProps {
  data: RootObject;
}

export interface RootObject {
  userid: string;
  msg: RootObjectMsg;
  type: string;
  status: number;
  id: string;
  createTime: number;
  noJsonMsg?: string;
  cacheTime: string;
}

export interface RootObjectMsg {
  subscribeSymbol: string;
  firstHand: number;
  contractType: number;
  spread: number;
  id: string;
  no: string;
  agentUserId: string;
  walletId: string;
  name: string;
  endPrice: number;
  num: number;
  plRatio: number;
  plAmount: number;
  status: number;
  type: number;
  limitPrice: number;
  endTime: number;
  symbolContractId: string;
  earnestMoney: number;
  lever: number;
  stopLossPrice: number;
  stopProfitPrice: number;
  commission: number;
  lockStatus: number;
  memberId: string;
  direction: number;
  source: number;
  startPrice: number;
  earnestMode: number;
  createTime: number;
  updateTime: number;
  creator: string;
  updater: string;
}

export function OrderMessageCard(props: OrderMessageCardProps) {
  const { data } = props;

  const { t } = useTranslation();
  // 处理内容
  const showContent = useMemo(() => {
    if (data) {
      switch (data?.type) {
        case SocketEnum.COIN_LIMIT_BY:
          return `${t('订单号为：{{orderId}} 的订单，币币订单委托成功', {
            orderId: data?.msg?.no,
          })}`;
        case SocketEnum.CONTRACT_FORCED_LIQUIDATION:
          return `${t('已完成全仓合约订单强平', {
            orderId: data?.msg?.no,
          })}`;
        case SocketEnum.ISOLATED_CONTRACT_FORCED_LIQUIDATION:
          return `${t('订单号为：{{orderId}} 的订单，已完成强平', {
            orderId: data?.msg?.no,
          })}`;
        case SocketEnum.CONTRACT_LIMIT:
          return `${t('订单号为：{{orderId}} 的订单，限价合约订单完成', {
            orderId: data?.msg?.no,
          })}`;
        case SocketEnum.COIN_LIMIT:
          return `${t('订单号为：{{orderId}} 的订单，限价币币订单完成', {
            orderId: data?.msg?.no,
          })}`;
        case SocketEnum.CONTRACT_STOP_PROFIT_LOSS:
          return `${t('订单号为：{{orderId}} 的订单，止盈止损成功', {
            orderId: data?.msg?.no,
          })}`;
        case SocketEnum.CONTRACT_STOP_PROFIT:
          return `${t('订单号为：{{orderId}} 的订单，止盈完成', {
            orderId: data?.msg?.no,
          })}`;
        case SocketEnum.CONTRACT_STOP_LOSS:
          return `${t('订单号为：{{orderId}} 的订单，止损完成', {
            orderId: data?.msg?.no,
          })}`;
        case SocketEnum.CONTRACT_LEAD_TRADING:
          return `${t('订单号为：{{orderId}} 的订单，跟单完成', {
            orderId: data?.msg?.no,
          })}`;
        case SocketEnum.CONTRACT_LEAD_TRADING_COMPLETE:
          return `${t('订单号为：{{orderId}} 的订单，平仓完成', {
            orderId: data?.msg?.no,
          })}`;
        case SocketEnum.ADMIN_ACTIVE:
          return data?.noJsonMsg;
      }
    }
  }, [data]);
  // 处理标题
  const showTitle = useMemo(() => {
    if (data) {
      switch (data?.type) {
        case SocketEnum.CONTRACT_FORCED_LIQUIDATION:
          return t(`全仓合约订单强平`);
        case SocketEnum.ISOLATED_CONTRACT_FORCED_LIQUIDATION:
          return t(`已完成强平`);
        case SocketEnum.COIN_LIMIT:
          return t(`限价币币订单完成`);
        case SocketEnum.COIN_LIMIT_BY:
          return t('币币订单委托成功');
        case SocketEnum.CONTRACT_LIMIT:
          return t(`限价合约订单完成`);
        case SocketEnum.CONTRACT_STOP_PROFIT_LOSS:
          return t(`止盈止损成功`);
        case SocketEnum.CONTRACT_STOP_PROFIT:
          return t(`止盈成功`);
        case SocketEnum.CONTRACT_STOP_LOSS:
          return t(`止损成功`);
        case SocketEnum.CONTRACT_LEAD_TRADING:
          return t(`跟单完成`);
        case SocketEnum.CONTRACT_LEAD_TRADING_COMPLETE:
          return t(`平仓完成`);
        case SocketEnum.ADMIN_ACTIVE:
          return t(`系统通知`);
      }
    }
  }, [data]);
  // 已读消息减1
  const { setNoReading, noReading } = useModel('socket');
  const { run: sendReadMessage } = useReq(sendReadMessageApi, {
    manual: true,
    onSuccess: () => {
      setNoReading(noReading - 1);
      if ([SocketEnum.COIN_LIMIT,SocketEnum.COIN_LIMIT_BY].includes(data?.type as any)) {
        history.push(
          `${PageEnum.CONTRACT_ORDER_DETAIL}/${(data?.msg as any)?.id}?type=coin`,
        );
        return;
      }
      if (data?.type === SocketEnum.ADMIN_ACTIVE) {
        return history.push(
          `${PageEnum.ORDER_MESSAGE_DETAIL}/?data=${JSON.stringify(data)}`,
        );
      }
      history.push(
        `${PageEnum.CONTRACT_ORDER_DETAIL}/${(data?.msg as any)?.id}`,
      );
    },
    debounceWait: 500,
  });

  return (
    <div
      onClick={() => {
        if (data?.status === 0) {
          sendReadMessage({ id: data?.id });
        } else {
          if ([SocketEnum.COIN_LIMIT,SocketEnum.COIN_LIMIT_BY].includes(data?.type as any)) {
            history.push(
              `${PageEnum.CONTRACT_ORDER_DETAIL}/${(data?.msg as any)?.id}?type=coin`,
            );
            return;
          }
          if (data?.type === SocketEnum.ADMIN_ACTIVE) {
            return history.push(
              `${PageEnum.ORDER_MESSAGE_DETAIL}/?data=${JSON.stringify(data)}`,
            );
          }
          history.push(`${PageEnum.CONTRACT_ORDER_DETAIL}/${data?.msg?.id}`);
        }
      }}
    >
      {/*时间*/}
      <div className="text-center text-xs text-auxiliaryTextColor mb-3">
        {dayjs(data?.createTime).format('YYYY-MM-DD HH:mm:ss')}
      </div>
      <div className="bg-backgroundAuxiliaryColor rounded-md mx-4 px-2 py-2">
        {/*标题*/}
        <div className="text-backContrastColor text-base font-bold mb-2 flex justify-between">
          <div>{showTitle}</div>
          {data?.status === 0 && (
            <div className="bg-errorColor  text-[#fff]  h-fit  py-1 rounded-md px-1 text-xs flex items-center justify-center">
              <span>{t('未读')}</span>
            </div>
          )}
        </div>
        {/*主体内容*/}
        <div className="text-sm leading-[1.8]">{showContent}</div>
        <Divider className="my-2 !bg-backgroundAuxiliaryColor" />
        <div className="flex justify-between text-xs  cursor-pointer py-1">
          <span className="text-auxiliaryTextColor">{t('查看更多')}</span>
          <PhCaretRight />
        </div>
      </div>
    </div>
  );
}
