import { PhCaretRight } from '@/assets/icons/comm/PhCaretRight';
import PageEnum from '@/enums/pageEnum';
import SocketEnum from '@/enums/socketEnum';
import { sendReadMessageApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { history } from '@@/core/history';
import { useModel } from '@@/exports';
import { Divider } from '@nextui-org/react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

interface OrderMessageCardProps {
  data: any;

  [key: string]: string;
}


export function GeneralMessageNotification(props: OrderMessageCardProps) {
  const { data, id } = props;
  const { t } = useTranslation();

  // 已读消息减1
  const { setNoReading, noReading } = useModel('socket');
  const { run: sendReadMessage } = useReq(sendReadMessageApi, {
    manual: true,
    onSuccess: () => {
      setNoReading(noReading - 1);
      history.push(PageEnum.VERIFIED);
      return;
    },
    debounceWait: 500,
  });

  return (
    <div
      onClick={() => {
        sendReadMessage({ id: id });
      }}
    >
      {/*时间*/}
      <div className="text-center text-xs text-auxiliaryTextColor mb-3">
        {dayjs(data?.createTime).format('YYYY-MM-DD HH:mm:ss')}
      </div>
      <div className="bg-backgroundAuxiliaryColor rounded-md mx-4 px-2 py-2">
        {/*标题*/}
        <div className="text-backContrastColor text-base font-bold mb-2 flex justify-between">
          <div>{t('实名认证失败')}</div>
          {data?.status === 0 && (
            <div className="bg-errorColor  text-[#fff]  h-fit  py-1 rounded-md px-1 text-xs flex items-center justify-center">
              <span>{t('未读')}</span>
            </div>
          )}
        </div>
        {/*主体内容*/}
        <div className="text-sm leading-[1.8]">{t('实名已失败 失败原因 {{remark}}', {
          remark: data?.remark,
        })}</div>
        <Divider className="my-2 !bg-backgroundAuxiliaryColor" />
        <div className="flex justify-between text-xs  cursor-pointer py-1">
          <span className="text-auxiliaryTextColor">{t('查看更多')}</span>
          <PhCaretRight />
        </div>
      </div>
    </div>
  );
}
