import PullToRefresh from '@/components/base/basePullToRefresh';
import BaseTabs from '@/components/base/baseTabs';
import BaseTopNav from '@/components/base/baseTopNav';
import SocketEnum from '@/enums/socketEnum';
import { usePaginationPage } from '@/hooks/usePaginationPage';
import { OrderMessageCard } from '@/pages/message/orderMessage/components/OrderMessageCard';
import OrderMessageDepositAndWithdrawalNews from '@/pages/message/orderMessage/components/OrderMessageDepositAndWithdrawalNews';
import {
  GeneralMessageNotification,
} from '@/pages/message/orderMessage/components/GeneralMessageNotification';
import useStateHooks from '@/pages/message/orderMessage/useStateHooks';
import { orderMessageApi, readOrderApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import RenderUtil from '@/utils/RenderUtil';
import { toJsonData } from '@/utils/socket';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * 订单消息页面
 */
export default () => {
  const { t } = useTranslation();

  // 筛选
  const { tabs, changeType, type } = useStateHooks();
  /**
   * 查询参数
   */
  const [params, setParams] = useState<any>({
    pageNo: 1,
    pageSize: 10,
    status: type,
  });

  /**
   * 消息列表数据
   */
  const { list, loading, loadMore, error, total, ...resetPageProps } =
    usePaginationPage({
      fn: orderMessageApi,
      params,
      setParams,
    });

  const listFormat = useMemo(() => {
    return list.map((item: any) => {
      return {
        ...item,
        cacheTime: RenderUtil.formatDate(item?.cacheTime,"YYYY-MM-DD HH:mm:ss"),
        msg: toJsonData(item?.msg),
        noJsonMsg: item?.msg,
      };
    });
  }, [list]);

  const { run: readOrderReq } = useReq(readOrderApi, {
    manual: true,
    onSuccess() {
      setParams({
        pageNo: 1,
        pageSize: 10,
        status: type,
      });
    },
  });


  // 一键已读
  const markAllRead = () => {
    readOrderReq();
  };
  return (
    <div>
      <BaseTopNav title={t('消息通知')}></BaseTopNav>
      <div className="px-4 flex justify-between items-center">
        <BaseTabs
          tabsPropsProps={{
            className: 'w-full',
            classNames: {
              tabList: 'pb-0',
            },
          }}
          value={type}
          onChange={(value: any) => {
            changeType(value);
            setParams({
              pageNo: 1,
              pageSize: 10,
              status: value,
            });
          }}
          options={tabs}
        />
        <div
          onClick={markAllRead}
          className="bg-backgroundAuxiliaryColor text-backContrastColor text-xs p-2 rounded-md"
        >
          {t('全部标记已读')}
        </div>
      </div>
      <PullToRefresh
        loadMore={loadMore}
        total={total}
        list={list}
        error={error}
        loading={loading}
        {...resetPageProps}
      >
        {listFormat.map((item: any) => {
          return (
            <div key={item?.id} className="mb-4">
              {[
                SocketEnum.REAL_NAME_REJECT,
              ].includes(item.type) && (
                <GeneralMessageNotification {...item} data={item?.msg} />
              )}
              {[
                SocketEnum.RECHARGE_SYSTEM_NOTICE,
                SocketEnum.WITHDRAWAL_SYSTEM_NOTICE,
                SocketEnum.WITHDRAW_REJECT,
              ].includes(item.type) && (
                <OrderMessageDepositAndWithdrawalNews {...item} />
              )}
              {[
                SocketEnum.CONTRACT_FORCED_LIQUIDATION,
                SocketEnum.ISOLATED_CONTRACT_FORCED_LIQUIDATION,
                SocketEnum.CONTRACT_LIMIT,
                SocketEnum.CONTRACT_STOP_PROFIT_LOSS,
                SocketEnum.COIN_LIMIT,
                SocketEnum.CONTRACT_LEAD_TRADING,
                SocketEnum.CONTRACT_STOP_LOSS,
                SocketEnum.CONTRACT_STOP_PROFIT,
                SocketEnum.COIN_LIMIT_BY,
                SocketEnum.CONTRACT_LEAD_TRADING_COMPLETE,
                SocketEnum.ADMIN_ACTIVE,
              ].includes(item.type) && <OrderMessageCard data={item} />}
            </div>
          );
        })}
      </PullToRefresh>
    </div>
  );
};
