import { PhCaretRight } from '@/assets/icons/comm/PhCaretRight';
import { CompletedOrderOrderStatusEnum } from '@/enums/businessEnum';
import PageEnum from '@/enums/pageEnum';
import SocketEnum from '@/enums/socketEnum';
import { sendReadMessageApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import RenderUtil from '@/utils/RenderUtil';
import { useModel } from '@@/exports';
import { Divider } from '@nextui-org/react';
import { history } from '@umijs/max';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

export default ({ status, msg, id, createTime, type }: IWithdrawField) => {
  const orderNum = createTime?.toString()?.slice(0, 5) + id.slice(0, 2);
  const { t } = useTranslation();
  const { setNoReading, noReading } = useModel('socket');
  const { run: sendReadMessage } = useReq(sendReadMessageApi, {
    manual: true,
    onSuccess: () => {
      setNoReading(noReading - 1);
      history.push(PageEnum.TRADINGHISTORYDETAIL, {
        id: (msg as any).id,
        type: type === SocketEnum.RECHARGE_SYSTEM_NOTICE ? 1 : 2,
      });
    },
    debounceWait: 500,
  });

  //标题
  const title = useMemo(() => {
    if (type === SocketEnum.RECHARGE_SYSTEM_NOTICE) {
      return t('充值');
    }
    return t('提现');
  }, [type]);
  return (
    <div
      onClick={() => {
        if (status === 0) {
          sendReadMessage({ id: id });
        } else {
          history.push(PageEnum.TRADINGHISTORYDETAIL, {
            id: (msg as any).id,
            type: type === SocketEnum.RECHARGE_SYSTEM_NOTICE ? 1 : 2,
          });
        }
      }}
    >
      {/*时间*/}
      <div className="text-center text-xs text-auxiliaryTextColor mb-3">
        {RenderUtil.formatTimeString(createTime)}
      </div>
      <div className="bg-backgroundAuxiliaryColor rounded-md mx-4 px-2 py-2">
        {/*标题*/}
        {msg?.status === +CompletedOrderOrderStatusEnum.DID_NOT_PASS && (
          <div className="text-backContrastColor text-base font-bold mb-2 flex justify-between">
            <div>
              {t('{{title}} 订单 {{orderNum}} 已经取消', {
                title,
                orderNum,
              })}
            </div>
            {status === 0 && (
              <div className="bg-errorColor  text-[#fff]  h-fit  py-1  rounded-md px-1 text-xs flex items-center justify-center">
                <span>{t('未读')}</span>
              </div>
            )}
          </div>
        )}
        {msg?.status === +CompletedOrderOrderStatusEnum.COMPLETED && (
          <div className="text-backContrastColor text-base font-bold mb-2 flex justify-between">
            <div>
              {t('{{title}} 订单 {{orderNum}} 已完成', {
                title,
                orderNum,
              })}
            </div>
            {status === 0 && (
              <div className="bg-errorColor   text-[#fff]  h-fit  py-1  rounded-md px-1 text-xs flex items-center justify-center">
                <span>{t('未读')}</span>
              </div>
            )}
          </div>
        )}
        {/*主体内容*/}
        {msg?.status === +CompletedOrderOrderStatusEnum.DID_NOT_PASS && (
          <div className="text-sm leading-[1.8]">
            {t(
              '{{title}} 订单已被系统取消。订单 {{orderNum}}。如果您对订单有疑问，请查看详情或联系客服。',
              {
                title,
                orderNum,
              },
            )}
          </div>
        )}
        {msg?.status === +CompletedOrderOrderStatusEnum.COMPLETED && (
          <div className="text-sm leading-[1.8]">
            {t(
              '您的 {{title}} 订单。(订单号： {{orderNum}}，订单金额：{{amount}}) 。已完成，请查看您的账户资金。',
              {
                title,
                orderNum,
                amount: msg?.amount,
              },
            )}
          </div>
        )}
        <Divider className="my-2 !bg-background" />
        <div className="flex justify-between text-xs  cursor-pointer py-1">
          <span className="text-auxiliaryTextColor">{t('查看更多')}</span>
          <PhCaretRight />
        </div>
      </div>
    </div>
  );
};

//公共字段
interface commonField {
  cacheTime: string;
  createTime: number;
  id: string;
  status: number; //消息状态
  type: SocketEnum.RECHARGE_SYSTEM_NOTICE | SocketEnum.WITHDRAWAL_SYSTEM_NOTICE;
}

//提现字段
interface IWithdrawField extends commonField {
  msg: {
    amount: number;
    // -1: 未通过 1: 已通过
    status: number;
  };
}
